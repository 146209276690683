import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

// types
import type { WRsvpEventView } from '@zola/svc-web-api-ts-client';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

// utils
import { formatRsvpEventDates } from 'components/publicWebsiteV2/pages/Rsvp/utils';
import { mapFontValuesToComponentFactory } from 'components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';

// styles
import { SPACING } from '@zola/zola-ui/src/styles/emotion';
import { Container, StyledAddress, Title, Venue } from './GuestRsvpEventDetails.styles';

type GuestRsvpEventDetailsProps = {
  event: WRsvpEventView;
  smallerHeaderFont?: boolean;
};

const GuestRsvpEventDetails: React.FC<GuestRsvpEventDetailsProps> = ({
  event,
  smallerHeaderFont,
}) => {
  const {
    state: {
      components: {
        styleCmsEntityHeaderFont,
        cmsEntityComponentHeaderFontValues,
        styleCmsEntityBodyFont,
      },
    },
  } = useWebsiteThemeContext();

  const { MEDIA_QUERY } = useTheme();

  const Para = styled.p`
    margin-bottom: ${SPACING.SM};
    white-space: pre-wrap;
    &:empty {
      display: none;
    }
  `;
  const styleCmsEntityHeaderFontSmaller = mapFontValuesToComponentFactory({
    fallback: {
      ...cmsEntityComponentHeaderFontValues,
      fontSize: cmsEntityComponentHeaderFontValues.fontSize - 6,
    },
    responsiveFontSize: true,
    mediaQuery: MEDIA_QUERY,
    excludeFontSize: false,
  });
  const styleHeaderFct = smallerHeaderFont
    ? styleCmsEntityHeaderFontSmaller
    : styleCmsEntityHeaderFont;
  const StyledTitle = styleHeaderFct(Title);
  const StyledP = styleCmsEntityBodyFont(Para);
  const StyledVenue = styleCmsEntityBodyFont(Venue);

  return (
    <Container>
      <StyledTitle>{event.name}</StyledTitle>
      <StyledP>{formatRsvpEventDates(event)}</StyledP>
      <StyledVenue>
        {event.venue_name}
        {event.venue_name && <br />}
        <StyledAddress
          singleLine
          addressData={{
            address1: event.address_1,
            address2: event.address_2,
            city: event.city,
            state: event.state_province,
            zip: event.postal_code,
            countryCode: event.country_code,
          }}
        />
      </StyledVenue>
      <StyledP>{event.attire}</StyledP>
      <StyledP>{event.note}</StyledP>
    </Container>
  );
};

export default GuestRsvpEventDetails;
